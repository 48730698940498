import React from 'react';
import { NavigationBar } from './navigationBar';
import { Footer } from './footer'
import { Modals } from './modal';
import { ScriptCDN } from './scriptCDN';
import { resources } from './locales.js'

export function TermsContent({ lang }) {

    const disclaimer = []
    if (lang !== resources.defaultTerms) {
        disclaimer.push(
            <>
                <br/><br/>
                <h4>{resources[lang]["termsUse"]["disclaimer"]}</h4>
            </>
        )
    }   

    return (
        <div>
            <NavigationBar lang={lang}></NavigationBar>

            <div class="container">

                <div class="row ">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-5 text-center text-zer-urdin">
                        <h1>{resources[lang]["termsUse"]["title"]}</h1>
                        {disclaimer}
                    </div>
                </div>

                <div class="row  justify-content-left">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-5 pt-5 ">
                        <p>Te agradecemos que uses el servicio de traducción Batua.eus (en adelante, el "Servicio"). Este Servicio te lo presta la Fundación Vicomtech (en adelante, "Vicomtech"), una compañía establecida En Donostia-San Sebastián y que opera bajo las leyes de España, y con sede en Parque Tecnológico de Miramon, Paseo Mikeletegi 57 de Donostia-San Sebastián.</p>
                        <p>El uso de nuestro Servicio implica la aceptación de estas condiciones. Te recomendamos que las leas detenidamente.</p>

                        <br/><br/>
                        <h2>Uso de nuestro Servicio</h2>
                        <br/>
                        <p>No debes usar nuestro Servicio de forma inadecuada. Por ejemplo, no debes interferir con dicho Servicio ni intentar acceder a él usando un método distinto a la interfaz y a las instrucciones proporcionadas por Batua. Solo podrás usar el Servicio en la medida en que las leyes en vigor lo permitan. Podemos suspender o denegar nuestro Servicio si no cumples con nuestras políticas o condiciones o si consideramos que tu conducta puede ser malintencionada.</p>
                        <p>El uso de nuestro Servicio no te convierte en titular de ninguno de los derechos de propiedad intelectual de los mismos. Estas condiciones no te otorgan el derecho a usar las marcas ni los logotipos utilizados en nuestro Servicio. No elimines, ocultes ni alteres los avisos legales que se muestren en nuestro Servicio.</p>
                        <p>Nuestro Servicio está disponible en dispositivos móviles. No utilices este Servicio de un modo que pueda distraerte y que te impida cumplir las leyes de tráfico o de seguridad.</p>

                        <br/><br/>
                        <h2>Tu cuenta de Batua</h2>
                        <br/>
                        <p>No es necesario disponer de una cuenta de BATUA para poder hacer uso del Servicio.</p>

                        <br/><br/>
                        <h2>Protección de la privacidad y de los derechos de autor</h2>
                        <br/>
                        <p>En nuestras Políticas de Privacidad se explica cómo utilizamos los datos personales y protegemos la privacidad en nuestro Servicio.</p>

                        <br/><br/>
                        <h2>Tu contenido en nuestro Servicio</h2>
                        <br/>
                        <p>Nuestro Servicio te permite subir contenido para su traducción y realizar correcciones sobre el texto traducido. Si lo haces, seguirás siendo el titular de los derechos de propiedad intelectual que tengas sobre ese contenido.</p>
                        <p>Al enviar contenido para su traducción o realizar correcciones en nuestro Servicio, concedes a BATUA (y a su propietaria Vicomtech) una licencia mundial para usar, alojar, almacenar, modificar, crear obras derivadas (por ejemplo, las que resulten de la traducción, la adaptación u otros cambios que realicemos para que tu contenido se adapte mejor a nuestro Servicio), de dicho contenido. BATUA usará los derechos que le confiere esta licencia únicamente con el fin de mejorar los algoritmos y modelos de traducción en los que se basa este Servicio y para desarrollar Servicio nuevos. Esta licencia seguirá vigente incluso cuando dejes de usar nuestro Servicio. Asegúrate de tener los derechos necesarios para concedernos esta licencia sobre cualquier contenido que envíes a nuestro Servicio.</p>
                        <p>Para obtener más información sobre cómo BATUA usa o almacena contenido, consulta la política de privacidad del Servicio.</p>

                        <br/><br/>
                        <h2>Acerca del software de nuestro Servicio</h2>
                        <br/>
                        <p>Si un Servicio requiere o incluye software que se puede descargar (por ejmplo las aplicaciones móviles de BATUA), este software podrá actualizarse automáticamente en tu dispositivo siempre que haya versiones o funciones nuevas disponibles. Algunos Servicio pueden permitir que definas los ajustes de actualización automática.</p>
                        <p>BATUA te concede una licencia personal mundial, libre de royalties, intransmisible y no exclusiva para usar el software que se te proporcione como parte del Servicio. El único propósito de esta licencia es permitirte usar el Servicio que ofrece BATUA y beneficiarte de ellos, según lo estipulado en estas condiciones. No podrás copiar, modificar, distribuir, vender ni prestar ninguna parte de nuestro Servicio ni del software incluido ni podrás aplicar técnicas de ingeniería inversa ni intentar extraer el código fuente de dicho software, salvo si la legislación prohíbe dichas restricciones o si tienes consentimiento de BATUA (o su propietario Vicomtech) por escrito.</p>
                        <p>BATUA otorga gran importancia al software de código abierto. Puede que algunas de las disposiciones establecidas en la licencia de software de código abierto anulen expresamente algunas de estas condiciones.</p>

                        <br/><br/>
                        <h2>Cómo modificar y cancelar nuestro Servicio</h2>
                        <br/>
                        <p>BATUA cambia y mejora sus Servicio constantemente. Por ello, es posible que añadamos o eliminemos algunas funciones o características, o que suspendamos o cancelemos un Servicio temporalmente o por completo.</p>
                        <p>Puedes dejar de usar el Servicio en cualquier momento. De igual modo, BATUA puede dejar de proporcionarte el Servicio o añadir o crear nuevas limitaciones en cualquier momento.</p>

                        <br/><br/>
                        <h2>Fase BETA o de lanzamiento en pruebas</h2>
                        <br/>
                        <p>BATUA se reserva el derecho de mantener la fase BETA del servicio el tiempo que técnicamente sea necesario. Dicho estado BETA o de lanzamiento en pruebas será notificado a los usuarios mediante un distintivo visible en los interfaces de BATUA.</p>

                        <br/><br/>
                        <h2>Nuestras garantías y renuncias de responsabilidad</h2>
                        <br/>
                        <p>BATUA ofrece sus Servicio con un nivel de competencia y diligencia razonable, y esperamos que disfrutes al usarlos. No obstante no podemos ofrecer garantías en relación con algunos aspectos de nuestro Servicio.</p>
                        <p>BATUA no ofrece garantías específicas sobre el Servicio distintas a las establecidas de forma expresa en estas condiciones o en condiciones adicionales. Por ejemplo, BATUA no ofrece ninguna garantía en relación con el contenido del Servicio, sus funciones específicas, su fiabilidad, su disponibilidad ni su capacidad para satisfacer tus necesidades. El Servicio se ofrecen «tal cual».</p>
                        <p>Algunas jurisdicciones establecen determinadas garantías, como la garantía específica de mercantibilidad, de idoneidad para un fin concreto y de no incumplimiento. En la medida en que la ley lo permita, BATUA excluye todas las garantías.</p>

                        <br/><br/>
                        <h2>Responsabilidad por nuestro Servicio</h2>
                        <br/>
                        <p>En los casos permitidos por la ley, BATUA no será responsable por la pérdida de beneficios, ingresos, datos, pérdidas financieras ni por daños indirectos, especiales, derivados, ejemplares o punitivos.</p>
                        <p>En ningún caso, BATUA será responsable por cualquier pérdida o daño que no sean previsibles de forma razonable.</p>
                        <p>BATUA reconoce que puedes tener derechos legales como consumidor en algunos países. Si usas el Servicio con fines personales, ninguna de las disposiciones establecidas en estas condiciones ni en ninguna de las condiciones adicionales limitará los derechos legales del consumidor a los que no se puede renunciar de forma contractual.</p>

                        <br/><br/>
                        <h2>Uso de nuestro Servicio por parte de empresas</h2>
                        <br/>
                        <p>Si usas el Servicio en nombre de una empresa, la empresa acepta estas condiciones. Se eximirá de responsabilidad a BATUA y a su propietario Vicomtech, directivos, agentes y empleados por las reclamaciones, demandas o acciones legales que se puedan derivar del uso de el Servicio o de la infracción de estas condiciones o que estén relacionados con los mismos, incluidos cualesquiera responsabilidad o gasto que se deriven de las reclamaciones, pérdidas, daños, demandas, juicios, costes procesales y honorarios de abogados.</p>

                        <br/><br/>
                        <h2>Acerca de estas condiciones</h2>
                        <br/>
                        <p>BATUA puede modificar estas condiciones o las condiciones adicionales que se apliquen a un Servicio para, por ejemplo, reflejar cambios legislativos o en el Servicio. Te recomendamos que consultes las condiciones de forma periódica. BATUA publicará avisos relacionados con las modificaciones de estas condiciones en esta página. Asimismo, publicará avisos relacionados con las modificaciones que se hagan en las condiciones adicionales del Servicio correspondiente. Las modificaciones no se aplicarán con carácter retroactivo y entrarán en vigor de forma inmediata. Si no aceptas las condiciones modificadas de un Servicio, deberás dejar de usar dicho Servicio.</p>
                        <p>Estas condiciones rigen la relación entre BATUA y tú, y no generan ningún derecho del que pueda ser beneficiario un tercero.</p>
                        <p>Si no cumples estas condiciones y BATUA no toma ninguna medida al respecto de forma inmediata, no se entenderá que BATUA renuncia a los derechos de los que pueda disponer (como, por ejemplo, llevar a cabo acciones en el futuro).</p>
                        <p>En el caso de que una condición determinada no sea de obligado cumplimiento, el resto de condiciones no se verán afectadas.</p>

                        <br/><br/>
                        <h2>Legislación y tribunales aplicables</h2>
                        <br/>
                        <p>Las condiciones de uso se regirán e interpretarán conforme a la normativa española. En caso de litigio las partes se someterán a la jurisdicción que corresponda según la normativa española vigente, y en los tribunales correspondientes al domicilio de BATUA.</p>
                        <p>Para obtener más información o para ponerte en contacto con BATUA, accede a la página de contacto (https://www.batua.eus/es/contacto/)</p>

                        <br/><br/>
                    </div>
                </div>
            </div>

            <Modals lang={lang}></Modals>
            <Footer lang={lang}></Footer>
            <ScriptCDN lang={lang} page="terms"></ScriptCDN>
        </div>
    );
}
