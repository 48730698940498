import React  from 'react';
import { resources, getHrefCode } from './locales.js'


export function Footer({ lang }) {

    var pathTerms = resources[lang]["paths"]["terms"]["slug"]
    var pathPrivacy = resources[lang]["paths"]["privacy"]["slug"]
    var pathPolicy = resources[lang]["paths"]["policy"]["slug"]

    var langPrefix = `${getHrefCode(lang)}`

    return (
        
        <footer class="py-3 mt-5 ">
            <div class="d-flex flex-row justify-content-around">
                <div class=" pl-4 d-none d-lg-block">
                    <figure class="figure">
                        <a href="http://vicomtech.org" target="_blank"><img src="/img/vicomtech_logo.svg" width="170em" class="figure-img img-fluid rounded" alt="Vicomtech logo."/></a>
                    </figure>
                    <br/>
                </div>
                <div class="d-none d-lg-block pt-2">
                    <span id="legal" class="pt-4"><a href={`/${langPrefix}${pathTerms}`}>{resources[lang]["paths"]["terms"]["text"]}</a> / <a href={`/${langPrefix}${pathPrivacy}`}>{resources[lang]["paths"]["privacy"]["text"]}</a> / <a href={`/${langPrefix}${pathPolicy}`}>{resources[lang]["paths"]["policy"]["text"]}</a></span>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center flex-row ">
                <div class="d-block d-lg-none pb-2 pt-0">
                    <figure class="figure">
                        <a href="http://vicomtech.org" target="_blank"><img src="/img/vicomtech_logo.svg"  width="140em" class="figure-img img-fluid rounded" alt="Vicomtech logo."/></a>
                        <figcaption class="figure-caption" style={{"font-size":"0.7em"}}><a href={`/${langPrefix}${pathTerms}`}>{resources[lang]["footerMobile"]["terms"]}</a> / <a href={`/${langPrefix}${pathPolicy}`}>{resources[lang]["footerMobile"]["policy"]}</a> / <a href={`/${langPrefix}${pathPrivacy}`}>{resources[lang]["footerMobile"]["privacy"]}</a></figcaption>
                    </figure>
                </div>
            </div>
        </footer>

    )
}